.post {
    width: 100%;
    margin: 0px 25px 40px 25px;
    display: flex;
    flex-direction: column;
}

.postImage {
    width: 100%;
    height: 280px;
    object-fit: cover;
    border-radius: 10px;
}

.postInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postCat {
    font-size: 10px;
    color: maroon;
    line-height: 20px;
    margin: 15px;
    margin-top: 15px;
    margin-right: 10px;
    cursor: pointer;
}

.postTitle {
    font-size: 25px;
    font-weight: 700;
    margin-top: 15px;
    cursor: pointer;
}

.postDate {
    font-style: italic;
    font-size: 15px;
    color: rgb(96, 95, 95);
    margin-top: 15px;
}

.postDescription {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #444444;
    margin-top: 15px;
}

.postDescriptionTitle {
    font-weight: bold;
    font-size: 18px;
}