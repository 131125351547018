.top{
    width: 100%;
    height: 50px;
    background-color: white;
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 999; /*stops overlap of header and topbar */
}

.topLeft, .topRight {
    flex: 3;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topCenter {
    flex: 6;
}

.topIcon{
    font-size: 30px;
    margin-right: 10px;
    color: green;
    cursor: pointer;
}

.topImage{
    width:40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.topList {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.topListItem {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 300;
    cursor: pointer;
}


.topSearchIcon {
    font-size: 20px;
    color:rgb(128, 0, 0);
    cursor: pointer;
    margin-left: 15px;
}
