.sidebar {
    flex: 3;
    margin: 20px;
    padding-bottom: 30px;
    background-color: white;
    border-left: 2px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebarItem {
display: flex;
flex-direction: column;
align-items: center;
}

.sidebarTitle {
    margin: 10px;
    padding: 5px;
    width: 80%;
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    font-size: 12px;
    color: rgb(30, 28, 28);
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.sidebarItem > img {
    margin-top: 15px;
    border-radius: 50px;
}

.sidebarItem > p {
    padding: 30px;
}

.sidebarList {
    list-style: none;
    margin-bottom: 30px;
}

.sidebarListItem {
    display: inline-block;
    width: 50%;
    margin-top: 15px;
    cursor: pointer;
}

.sidebarSocial {
    margin-top: 15px;
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black
}

.sidebarIcon {
    font-size: 16px;
    margin-left: 15px;
    cursor: pointer;
}