.singlePost {
    flex: 9;
}

.singlePostWrapper {
    padding: 20px;
    padding-right: 0;
}

.singlePostImage {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
}

.singlePostTitle {
    text-align: center;
    margin: 10px;
    font-size: 30px;
}

.singlePostEdit {
    float: right;
    font-size: 20px;
}

.singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
}

.singlePostIcon:first-child {
    color: green;

}

.singlePostIcon:last-child {
    color: red;
}

.singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: maroon;
}


.singlePostText {
    color: black;
    font-size: 18px;
    line-height: 25px;
}

.singlePostText:first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
}