.header {
    margin-top: 10px;
}

.headerTitles {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
}

.headerTitleSm {
    position: absolute;
    top: 18%;
    font-size: 20px;
}

.headerTitleLg {
    position: absolute;
    top: 20%;
    font-size: 100px;
}

.headerImage {
    width: 100%;
    height: 450px;
    margin-top: 80px;
    object-fit: cover;
}