.login {
    height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
    rgba(255,255,255,0.5),
    rgba(255,255,255,0.5)),
    url("https://wallpapersok.com/images/hd/composer-workspace-blogging-backdrop-4ferx6eq7jp9z9im.jpg");
    
    background-size: cover;
}

.loginTitle {
    font-size: 50px;
}

.loginForm {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}


.loginForm > label {
    margin: 10px 0;
}


.loginInput {
    padding: 10px;
    background-color: white;
    border: none;
}

.loginButton {
    margin-top: 20px;
    cursor: pointer;
    border: none;
    background-color: maroon;
    color: tan;
    border-radius: 10px;
    padding: 10px;
}

.loginSignupButton {
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: maroon;
    color: tan;
    border-radius: 10px;
    cursor: pointer;
    border: none;
    padding: 10px;
}